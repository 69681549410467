<template>
  <v-data-table
    :headers="generatedHourList"
    :items="sentedPhonesList"
    item-key="title"
    class="elevation-1 table-calls"
    :items-per-page="100"
    :disable-sort="true"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat class="toolbar-header-calls">
        <v-toolbar-title>Переданные звонки</v-toolbar-title>
        <div class="d-flex justify-space-between flex-row">
          <v-menu
            v-model="date_menu"
            style="margin-left: -150px"
            :close-on-content-click="false"
            content-class="data-picker"
            transition="slide-y-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDate"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                class="date-selector"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="selectedDate"
              no-title
              event-color="#2913f3"
              color="#2913f3"
              @input="date_menu = false"
            ></v-date-picker>
          </v-menu>
          <!--<v-btn @click="addRow()">Обновить</v-btn>-->
        </div>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "SentedCalls",
  data() {
    return {
      phones: [],
      rowsTime: "today",
      selectedDate: "",
      date: null,
      date_menu: false,
      dateFormatted: "",
      objectHeaders: [{ text: "Имя", value: "from" }],
      timeItems: [
        { text: "Сегодня", value: "today" },
        { text: "Всё время", value: "all" },
      ],
    };
  },
  computed: {
    ...mapGetters(["sentedPhonesList"]),
    generatedHourList() {
      let hourList = [{ text: "Имя", value: "title" }];
      for (let i = 0; i < 24; i++) {
        hourList.push({ text: i, value: String(i) });
      }
      return hourList;
    },
    computedDate() {
      return this.formatDateStyle(this.selectedDate);
    },
  },
  methods: {
    ...mapActions(["updatePhones", "addPhone"]),
    formatDateStyle(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    async autoUpdate() {
      setInterval(() => {
        this.updatePhones(this.selectedDate);
      }, 10 * 1000 * 60);
    },
    async addRow() {
      await this.addPhone();
      await this.updatePhones(this.selectedDate);
    },
  },
  created() {
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.autoUpdate();
  },
  watch: {
    selectedDate() {
      this.updatePhones(this.selectedDate);
    },
  },
};
</script>

<style>
.table-calls th {
  /* min-width: 70px;*/
}
.table-calls th:first-child {
  /*min-width: 200px;*/
}
.date-selector {
  margin: 0 15px -20px 15px;
  max-width: 150px !important;
}
.toolbar-header-calls .v-toolbar__content {
  justify-content: space-between;
}
.data-picker {
  margin-left: -140px;
}
</style>
